<template>
    <div class="login">
        <div class="top_bar">
            <div>
                <img src="@/assets/imgs/logo_big.png" />
            </div>
        </div>
        <div class="main">
            <div class="ls_flex_center">
                <img src="@/assets/imgs/pic_Left Img.png" />
                <div class="login_bk">
                    <div class="type_1" v-if="type === 'scan'">
                        <div>微信扫码登录</div>
                        <div>绿数花旅助力插花教育高质量发展</div>
                        <div id="qrcode" ref="qrcode"></div>
                    </div>
                    <div class="type_2" v-if="type === 'info'">
                        <div>填写信息完成注册</div>
                        <div>准确填写信息，让我们更好的为您匹配体验功</div>
                        <el-form label-width="90px">
                            <el-form-item label="手机号">
                                <el-input
                                    v-model="info.phone"
                                    maxlength="11"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="验证码">
                                <el-input v-model="info.code" maxlength="4">
                                    <template slot="append">
                                        <div class="code_btn" @click="getCode">
                                            {{
                                                cutdown
                                                    ? cutdown + "s"
                                                    : "获取验证码"
                                            }}
                                        </div>
                                    </template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="您的身份">
                                <el-select
                                    v-model="info.identity"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        label="学校老师"
                                        value="学校老师"
                                    ></el-option>
                                    <el-option
                                        label="培训机构"
                                        value="培训机构"
                                    ></el-option>
                                    <el-option
                                        label="学生"
                                        value="学生"
                                    ></el-option>
                                    <el-option
                                        label="插花爱好者"
                                        value="插花爱好者"
                                    ></el-option>
                                    <el-option
                                        label="插花师"
                                        value="插花师"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="想解决问题">
                                <el-select
                                    v-model="info.question"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        label="想要教学课件"
                                        value="想要教学课件"
                                    ></el-option>
                                    <el-option
                                        label="找数字插花作品"
                                        value="找数字插花作品"
                                    ></el-option>
                                    <el-option
                                        label="办插花比赛"
                                        value="办插花比赛"
                                    ></el-option>
                                    <el-option
                                        label="数字化虚拟插花解决方案"
                                        value="数字化虚拟插花解决方案"
                                    ></el-option>
                                    <el-option
                                        label="节省花材损耗"
                                        value="节省花材损耗"
                                    ></el-option>
                                    <el-option
                                        label="其他"
                                        value="其他"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <div class="login_btn" @click="login">登录</div>
                        </el-form>
                    </div>
                    <div class="type_3" v-if="type === 'choose'">
                        <div>您可以使用以下账户</div>
                        <div>
                            您已绑定多个账户，当前可选择一个账户登录，之后也能
                            进行切换。
                        </div>
                        <div class="school_list">
                            <div
                                v-for="item in schoolList"
                                :key="item.organizationId"
                                @click="change(item)"
                            >
                                {{ item.tenantName }}
                            </div>
                        </div>
                    </div>
                    <div class="login_bk_tip">
                        登录即视为您同意我们的
                        <a
                            href="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/static/%E7%BB%BF%E6%95%B0%E8%8A%B1%E6%97%85_%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.docx"
                        >
                            《隐私政策》
                        </a>
                        和
                        <a
                            href="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/static/%E7%BB%BF%E6%95%B0%E8%8A%B1%E6%97%85_%E7%94%A8%E6%88%B7%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.docx"
                        >
                            《服务条款》
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom_bar">
            <div>
                <div class="ls_flex">
                    <a href="https://www.lvshuhualv.com/" target="_blank">
                        解决方案
                    </a>
                    <a href="https://www.lvshuhualv.com/work" target="_blank">
                        数字作品
                    </a>
                    <a href="https://www.lvshuhualv.com/price" target="_blank">
                        售卖价格
                    </a>
                    <a href="https://www.lvshuhualv.com/about" target="_blank">
                        关于我们
                    </a>
                    <div>全国服务热线：13635275043</div>
                </div>
                <div class="beian">
                    © 2023,©福州绿数花旅网络科技有限公司 版权所有 备案号：
                    <a href="https://beian.miit.gov.cn" target="_blank">
                        <img
                            src="https://lvshuhualv.oss-cn-hangzhou.aliyuncs.com/static/beian.png"
                        />
                        闽ICP备2023001658号
                    </a>
                    <br />
                    <!-- 地址：福州市高新区XXXXX -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
    data() {
        return {
            qrcodeItem: "",
            info: {
                phone: "",
                code: "",
                identity: "学校老师",
                question: "想要教学课件",
            },
            cutdown: 0,
            sceneStr: "",
            tId: "",
            type: "scan",
            schoolList: [],
        };
    },
    methods: {
        change(item) {
            this.$ajax
                .post("/user/user/changeOrganization", {
                    organizationId: item.organizationId,
                })
                .then(res => {
                    window.location.href = "./";
                });
        },
        getSchoolList() {
            this.$ajax.post("user/user/getOrganizationList").then(res => {
                this.schoolList = res.data;
                if (this.schoolList.length >= 1) {
                    window.location.href = "/";
                }
            });
        },
        //获取验证码
        getCode() {
            if (!/^1\d{10}$/.test(this.info.phone)) {
                this.$message({
                    message: "请输入正确的手机号",
                    type: "warning",
                });
                return;
            }
            if (this.cutdown) {
                return;
            }
            this.cutdown = 60;
            this.$ajax.post("/login/sms/send", {
                phone: this.info.phone,
                smsType: "REGISTER",
            });
            let tId = setInterval(() => {
                this.cutdown--;
                if (!this.cutdown) {
                    clearInterval(tId);
                }
            }, 1000);
        },
        //登录
        login() {
            if (!/^1\d{10}$/.test(this.info.phone)) {
                this.$message({
                    message: "请输入正确的手机号",
                    type: "warning",
                });
                return;
            }
            if (!/^\d{4}$/.test(this.info.code)) {
                this.$message({
                    message: "请输入正确的验证码",
                    type: "warning",
                });
                return;
            }

            this.$ajax
                .post("/login/login", {
                    type: this.sceneStr ? "1" : "0",
                    loginSercet: this.sceneStr,
                    phone: this.info.phone,
                    smsCode: this.info.code,
                    job: 10001,
                    question: this.info.question,
                })
                .then(res => {
                    if (res.code == 200) {
                        this.getSchoolList();
                    }
                });
        },
        // 获取登录二维码
        getScanCode() {
            this.$ajax.post("login/qrcode/getWechat").then(res => {
                this.qrcodeItem = new QRCode("qrcode", {
                    width: 214, // 设置宽度，单位像素
                    height: 214, // 设置高度，单位像素
                    text: res.data.url, // 设置二维码内容或跳转地址
                });
                this.qrcodeItem._el.title = "";
                this.sceneStr = res.data.loginSercet;
                this.waitScan();
            });
        },
        waitScan() {
            this.$ajax.post("/login/scanResult/" + this.sceneStr).then(res => {
                switch (res.data.result) {
                    // 待扫码
                    case 0:
                        setTimeout(() => {
                            this.waitScan();
                        }, 500);
                        break;
                    case 1:
                        // 已扫码未注册
                        this.qrcodeItem.clear();
                        window.clearInterval(this.tId);
                        this.type = "info";
                        break;
                    case 2:
                        // 已扫码已注册
                        this.$refs.qrcode.innerHTML = "";
                        window.clearInterval(this.tId);
                        this.type = "choose";
                        this.$message({
                            message: "扫码成功",
                            type: "success",
                        });
                        this.$ajax
                            .post("/login/login", {
                                type: this.sceneStr ? "1" : "0",
                                loginSercet: this.sceneStr,
                            })
                            .then(res => {
                                if (res.code == 200) {
                                    this.getSchoolList();
                                }
                            });
                        break;
                    default:
                        this.$message({
                            message: result.msg,
                            type: "error",
                        });
                }
            });
        },
    },
    mounted() {
        this.getScanCode();
    },
};
</script>
<style>
.login .el-select {
    width: 282px;
}
</style>
<style lang="scss" scoped>
.beian {
    font-size: 12px;
    color: #7c93ad;
    line-height: 20px;
}
.bottom_bar {
    padding: 23px 0 32px 0;
    border-top: 1px solid #e9e9e9;
    > div {
        width: 750px;
        margin: 0 auto;
    }
    .ls_flex {
        > * {
            font-size: 14;
            color: #565656;
            line-height: 40px;
            + * {
                margin-left: 80px;
            }
        }
    }
}
.school_list {
    margin-top: 10px;
    > div {
        line-height: 50px;
        height: 50px;
        font-size: 18px;
        padding-left: 10px;
        cursor: pointer;
        + div {
            border-top: 1px solid #eee;
        }
        &:hover {
            background: #eee;
        }
    }
}
.code_btn {
    cursor: pointer;
    width: 70px;
    text-align: center;
}
.login_btn {
    width: 372px;
    line-height: 50px;
    background: #ffc928;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 25px;
    cursor: pointer;
}
.type_2 {
    padding: 20px 30px 0 30px;
    > div {
        &:nth-child(1) {
            font-size: 26px;
            line-height: 37px;
        }
        &:nth-child(2) {
            font-size: 14px;
            line-height: 20px;
            color: #999;
            margin-bottom: 15px;
        }
    }
}
.type_1 {
    text-align: center;
    padding-top: 37px;
    > div {
        &:nth-child(1) {
            font-size: 32px;
            line-height: 45px;
        }
        &:nth-child(2) {
            font-size: 14px;
            line-height: 20px;
            color: #999;
            position: relative;
            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 28px;
                height: 1px;
                background: #e9e9e9;
                top: 10px;
            }
            &:before {
                margin-left: -33px;
            }
            &:after {
                margin-left: 5px;
            }
        }
        &:nth-child(3) {
            width: 214px;
            height: 214px;
            border-radius: 8px;
            background: #eeeeee;
            margin-top: 30px;
            margin: 40px auto 40px auto;
        }
    }
}
.login {
    min-height: 100vh;
    min-width: 100vw;
    background: #fff;
    .top_bar {
        height: 140px;
        border-bottom: 1px solid #e9e9e9;
        > div {
            height: 100%;
            width: 1330px;
            margin: 0 auto;
            padding-top: 20px;
            img {
                height: 96px;
            }
        }
    }
    .main {
        padding: 77px 0;
        border-bottom: 1px solid #e9e9e9;
        .login_bk {
            margin-left: 141px;
            width: 432px;
            height: 432px;
            border-radius: 15px;
            border: 1px solid #e5e5e5;
            position: relative;
            .login_bk_tip {
                position: absolute;
                bottom: 15px;
                line-height: 20px;
                width: 100%;
                text-align: center;
                span {
                    color: #007cd9;
                    cursor: pointer;
                }
            }
        }
    }
}
.type_3 {
    padding: 30px;
    > div {
        &:nth-child(1) {
            font-size: 26px;
            line-height: 37px;
        }
        &:nth-child(2) {
            font-size: 14px;
            color: #999;
            margin-top: 3px;
            line-height: 20px;
        }
    }
}
</style>
